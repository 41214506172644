body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* background-image: url(/fundo-opt.svg); */
    background: url(/2021/fundo.png);
    background-size: 40%;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    background-color: #f1f0ee;
    min-height: 100vh;
}
body.main-page {
    background: url(/2021/fundo-main-pattern2.png);
    background-size: auto 90%;
    background-position: bottom;
    background-repeat: repeat-x;
}

body.main-page .dashboard {
    background-color: #fff;
    /* background-color: #fffffff3; */

    /* border-radius: 5px; */
    margin: 8rem auto 2rem;
    /* box-shadow: 1px 1px 7px #90a4ae; */
    border: 1px solid #333;

    position: relative;
    min-height: 400px;
    min-height: 50vh;
    padding-top: 10px;
}
body.main-page .dashboard::after {
    content: "";
    position: absolute;
    top: 0;
    right: 20px;
    background: url(/2021/moca.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 100px;
    width: 100px;
    transform: translateY(-100%);
}
body.main-page .dashboard h1.form-title {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-1px, -50%);

    background-color: #fff;
    border: 1px solid #333;
    padding: 0.1rem 1rem;
    margin: 0;

    background-color: #dc107a;
    color: #fff;
    font-size: 1.8rem;
    text-transform: uppercase;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.i {
    font-style: italic;
    color: #666;
}

.c {
    text-align: center;
}
.c.img img {
    max-height: 30rem;
    max-height: 45vh;
}
.c.img.tvtem img {
    max-height: 10vh;
}
.pw {
    white-space: pre-wrap;
}
.flex {
    display: flex;
}
.r {
    text-align: right;
}

.hidden {
    display: none;
}

h1 {
    color: #513b60;
    border-bottom: 1px solid #513b60;
    padding-bottom: 4px;
    font-size: 1.5em;
    margin: 0.3em 0 0.67em 0;

    user-select: none;
}
h2 {
    font-size: 1.3rem;
    /* font-style: italic; */
    margin: 0 0 1rem 0;
    user-select: none;
}

header {
    display: flex;
    transition: transform 0.25s ease-in-out;
    color: #fff;
    background: linear-gradient(90deg, #7f30cb, #d03882, #fd9a10);
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1000;
}
header .logo {
    width: 150px;
    display: flex;
    align-items: center;
    height: 60px;
}
header .logo svg {
    width: 100%;
    max-height: 1.8rem;
}

header .user-info {
    text-align: right;
    padding: 0.2rem 0.5rem;
    font-size: 0.9em;

    display: flex;
    align-items: center;
    user-select: none;
}
header .user-info a {
    text-decoration: none;
    color: #fff;
    font-size: 0.9em;
}

.fill {
    flex-grow: 1;
    flex-basis: 0;
}

/* FORM */
* {
    box-sizing: border-box;
}

.form {
    padding: 1rem;
}

.form label {
    user-select: none;
}

.fields {
    display: flex;
}
.fields > .field {
    margin-left: 1rem;
}
.fields > .field:first-child {
    margin-left: 0;
}

.field {
    margin-bottom: 0.7rem;
}

.field :disabled {
    pointer-events: none;
    opacity: 0.45;
}

.field label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
}
.field.checkbox label {
    display: inline;
    font-weight: normal;
    margin-left: 0.35rem;
}

.field input[type="text"],
.field input[type="email"],
.field input[type="date"],
.field input[type="phone"],
.field input[type="url"],
.field select,
.field textarea {
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    /* -webkit-appearance: none; */
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.45);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color 0.1s ease, border-color 0.1s ease;

    width: 100%;
    vertical-align: top;
}
.field textarea {
    resize: vertical;
}
.field .field-message {
    font-size: 0.9em;
    color: #333;
    margin: 0.3rem 0 0.5rem 0;
}
.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0, 0, 0, 0.6);
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
        background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
        box-shadow 0.1s ease, background 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
        box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;

    min-width: 100px;
    font-size: 0.85rem;

    border: 1px solid #000;
    text-transform: uppercase;
}
.button:hover {
    background-color: #cacbcd;
    background-image: none;
    box-shadow: 0 0 0 1px transparent inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    color: rgba(0, 0, 0, 0.8);
}
.button.purple {
    background-color: #5b20cf;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.button.purple:hover {
    background-color: #4f15c1;
    color: #fff;
    text-shadow: none;
}
.button.red {
    background-color: #d02121;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.button.red:hover {
    background-color: #c11515;
    color: #fff;
    text-shadow: none;
}
.button.green {
    background-color: #1db94c;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.button.green:hover {
    background-color: #13aa40;
    color: #fff;
    text-shadow: none;
}
.button.blue {
    background-color: #2185d0;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.button.primary {
    background-color: #dc107a;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.button.blue:hover {
    background-color: #1678c2;
    color: #fff;
    text-shadow: none;
}
.button.primary:hover {
    background-color: #e81181;
    color: #fff;
    text-shadow: none;
}
.button.secondary {
    background-color: #e5d010;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.button.secondary:hover {
    background-color: #eed712;
}

.button:disabled {
    opacity: 0.6;
    pointer-events: none;
}
.button.sm {
    font-size: 0.85em;
    padding: 0.5em 0.8em;
}

.validator-msg,
.field .validator-message {
    font-weight: normal;
    margin-left: 1rem;
    font-size: 0.8em;
    font-style: italic;
    color: #d32f2f;
    display: none;
}
.validator-msg {
    display: block;
    font-size: 0.95rem;
    margin: 1rem 0;
    font-weight: bold;
}
.field[aria-invalid="true"] input {
    border-color: #d32f2f;
}
.field[aria-invalid="true"] .validator-message {
    display: inline;
}

.field.ro > span {
    white-space: pre-wrap;
}

/* TABLE */
table td,
table th {
    padding: 0.2rem 0.5rem;
    border: 1px solid #b0bec5;
    border-width: 1px 0;
}
table {
    border-collapse: collapse;
    width: 100%;
}
table th {
    background: #b0bec5;
    text-align: left;
}
table .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
table .button.sm {
    min-width: 80px;
}

table .player-details + tr {
    display: none;
}
table .player-details.open td {
    border-bottom-width: 0;
}
table .player-details.open + tr {
    display: table-row;
}
table .player-details.open + tr td {
    border-top-width: 0;
}

.player-entry {
    border: 1px solid #b0bec5;
    border-radius: 5px;
    margin: 0.2rem 0 0.4rem 0;

    font-size: 0.9rem;
    user-select: none;
}
.player-entry h3 {
    margin: 0;
    background: #b0bec5;
}
.player-entry > * {
    padding: 0.2rem 0.5rem;
}

/* Admin */
.admin-links {
    background: #607d8b;
    padding: 0.5rem 1rem;
}
.admin-links a {
    color: #fff;
    margin: 0 0.5rem;
    text-decoration: none;
}
.admin-links a:hover {
    text-decoration: underline;
}
.form.admin h1 {
    font-size: 1.2rem;
    border-bottom: 0;
}
.form.admin .fields > * {
    flex-basis: 0;
    min-width: 12rem;
}
.form.admin .field input,
.form.admin .field select,
.form.admin .field textarea {
    font-size: 0.9rem;
}
.form.admin table .c .button.nomargin {
    margin: 0;
}

.player-details-btn {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;

    cursor: pointer;
}
.player-details.open .player-details-btn {
    transform: rotate(90deg);
}

.msg-expired {
    color: red;
    margin: 1rem 0;
    display: block;
}

@media screen and (max-height: 650px) {
    .c.img.tvtem,
    .welcome-page a.button {
        margin-top: 1rem;
    }

    .welcome-page div.hero.bar {
        height: 35px;
    }
}
