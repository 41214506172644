.welcome-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-bottom: 30px;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
}
.welcome-page .bg {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    align-items: flex-end;
}
.welcome-page .bg img {
    height: 50%;
}
.welcome-page .bg.left {
    background-image: url(/2021/fundo-ap-left.png);
    background-position: right;
    justify-content: flex-start;
}
.welcome-page .bg.right {
    background-image: url(/2021/fundo-ap-right.png);
    background-position: left;
    justify-content: flex-end;
}

.welcome-page .hero {
    position: absolute;
    bottom: 0;
}
.welcome-page .hero.bar {
    background: #dc107a;
    left: 0;
    right: 0;
    height: 50px;
}
.welcome-page .hero.img {
    max-width: 23%;
    height: 50%;
}
.welcome-page .hero.img.left {
    left: 10px;
}
.welcome-page .hero.img.right {
    right: 10px;
}

.welcome-page::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url(/fundo-papel-bemvindo.jpg); */
    background: url(/2021/fundo.png);
    background-size: 500px;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */

    z-index: -1;
}
body .dashboard.welcome {
    background-color: transparent;
    border-radius: 0;
    margin: 0 auto;
    box-shadow: none;
    margin: 0 2rem;
}

body .dashboard.admin {
    background: #f7f7f7;
    margin-top: 2rem;

    border: 1px solid #aaa;
    border-radius: 3px;
    box-shadow: 1px 1px 10px #0000002e;
}

.dashboard .team-list .team-types {
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}
.dashboard .team-list .team-types .button {
    width: 240px;
    margin: 1rem;
    padding: 1rem;
}

.welcome-page .button {
    margin-top: 3rem;
}
.welcome-page img {
    max-width: 90vw;
}
.welcome-page .tvtem {
    margin-top: 5rem;
}

.form.confirmed p {
    text-align: center;
    color: #55198b;
    font-size: 1.2rem;
    max-width: 600px;
    margin: 1rem auto;
}
.form.confirmed .main-info {
    font-weight: bold;
    font-size: 1.5rem;
}

.team-list .label {
    font-weight: bold;
    font-size: 0.85rem;
    margin-top: 0.8rem;
}
.team-list .label:first-child {
    margin-top: 0;
}
.team-list button {
    margin-top: 1rem;
}

table.tbl {
    font-size: 0.85rem;
    margin-top: 0.15rem;

    table-layout: fixed;
}

.box {
    border: 1px solid #b0bec5;
    border-left-width: 5px;
    border-left: 5px solid #513b60;
    border-radius: 5px;
    padding: 10px;

    margin-bottom: 0.67em;
}

.part-info {
    margin-bottom: 0.5rem;
    display: block;
}

.warning {
    background: #eceff1;
    padding: 4px 10px;
    border-radius: 4px;

    user-select: none;
}
.warning b {
    margin-top: 0.3rem;
    margin-bottom: 0.7rem;
    display: block;
    color: #000;
}
.warning span {
    color: #37474f;
    font-size: 0.9em;
}

.warning a {
    text-decoration: none;
    color: #000;
}

.progress {
    height: 10px;
    border: 1px solid #000;
    min-width: 15rem;
}
.progress > div {
    height: 100%;
    background-color: #0d47a1;
}

.ckb-confirmation {
    display: flex;
    margin-top: 1.3rem;
}

.warn.green {
    font-weight: bold;
    user-select: none;
    color: #00796b;
    margin: 1em 0 0.3em;
}

.form.confirmed .new-movie-question {
    text-align: center;
    margin-top: 5em;
}
.form.confirmed .new-movie-question .button {
    margin: 0 0.3rem;
    min-width: 120px;
}

button .special-dog-logo {
    width: 120px;
    display: inline-block;
    margin-top: 7px;
}

.team-types .button {
    margin-bottom: 4px;
}

@media screen and (min-width: 1300px) {
    .dashboard {
        max-width: 1200px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 540px) {
    .player-actions .button {
        display: block;
        margin-bottom: 0.4rem;
        min-width: 10rem;
    }

    .form .fields {
        display: block;
    }
    .form .fields > .field {
        margin-left: 0;
    }

    /* .team-types .button {
        display: block;
        width: 100%;

        margin-bottom: 0.75rem;
    } */

    .dashboard .team-list .team-types {
        flex-direction: column;
        align-items: center;
    }
    .dashboard .team-list .team-types .button {
        height: 100px;
        width: 90%;
    }

    body .dashboard {
        background-color: #fff;
        border-radius: 0;
        margin: 0 auto;
        box-shadow: none;
    }

    body.main-page .dashboard {
        margin-top: 2rem;
    }
    body.main-page .dashboard::after {
        display: none;
    }
    body.main-page .dashboard h1.form-title {
        position: relative;
        width: 100%;
    }
}
